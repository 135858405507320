import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatDate } from "../../utils/formateDate";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import { FormControlLabel, Switch } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import SubmitButton from "../Common/buttons/SubmitButton";
import { useQueryClient } from "@tanstack/react-query";

const UserProfileExtra = ({
  profile,
  handleClosePasswordModal,
  handleCloseTransPasswordModal,
  handleCloseRankView,
  moduleStatus,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [cancelLoader, setCancelLoader] = useState(false);

  const plan = useSelector(
    (state) => state?.dashboard?.appLayout?.moduleStatus?.mlm_plan
  );
  const [showWarning, setShowWarning] = useState(false);
  const cancelSubscriptionMutation = ApiHook.CallCancelSubscription();

  const handleSubscriptionToggleBtn = () => {
    if (1) {
      setShowWarning(true);
    }
  }
  const handleCancellation = () => {
      setCancelLoader(true);
      cancelSubscriptionMutation.mutateAsync(
        {},
        {
          onSuccess: (res) => {
            if (res.status) {
              setShowWarning(false);
              setTimeout(() => {
                queryClient.invalidateQueries({ queryKey: ['profile'] });
                setCancelLoader(false);
                toast.success(t(res.data));
              }, 4000);
             
            } else {
              console.log("error-log",res);
              toast.error(t('errorOccuredPleaseTryAgain'));
              setCancelLoader(false);
              setShowWarning(false);
            }
          },
        }
      );
  };

  return (
    <>
      <div id="animation" className="col-lg-9 col-md-12 border-prf-left">
        <div className="profDetailuserDtl">
          <div>
            <h5>{t("email")}</h5>
            {profile?.email ? <p>{profile?.email}</p> : <Skeleton width={200} />}
            {/* email verification need check with BE */}
            {moduleStatus?.kyc_status === 1 &&
              (moduleStatus?.kyc_status === 1 ? (
                <p className="text-success">{t("verified")} </p>
              ) : (
                <p className="text-danger">{t("notVerified")} </p>
              ))}
          </div>
          <div>
            <h5>{t("resetPassword")}</h5>
            <p>*************</p>
            <div className="chngPassBtn">
              <button
                type="button"
                className="btn btn-change"
                data-bs-toggle="modal"
                onClick={handleClosePasswordModal}
                style={{ padding: '0px' }}
              >
                {t("resetPassword")}
              </button>
            </div>
          </div>
          <div>
            <h5>{t("resetTransactionPassword")}</h5>
            <p>*************</p>
            <div className="chngPassBtn">
              <button
                type="button"
                className="btn btn-change"
                data-bs-toggle="modal"
                onClick={handleCloseTransPasswordModal}
                style={{ padding: '0px' }}
              >
                {t("resetTransactionPassword")}
              </button>
            </div>
          </div>
          {!!moduleStatus?.rank_status && (
            <div>
              <h5>{t("current_ranking")}</h5>
              {profile?.rankDetails?.currentRank !== null ? (
                <p>
                  {profile?.rankDetails?.currentRank?.name ?? (
                    <Skeleton width={200} />
                  )}
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    type="button"
                    onClick={handleCloseRankView}
                  >
                    <i className="fa-solid fa-eye view_rank_dtl"></i>
                  </a>
                </p>
              ) : (
                <p>{t("no_rank_achieved")}</p>
              )}
            </div>
          )}
          {!!profile?.subscriptionDetails?.autoRenewalStatus &&
            <div>
              <h5>{t("auto_renewal")}</h5>
              <FormControlLabel
                control={
                  <Switch
                    checked={profile?.subscriptionDetails?.autoRenewalStatus}
                    onChange={handleSubscriptionToggleBtn}
                    color="secondary"
                  />
                }
                label={t("auto-pay")}
                labelPlacement="start"
              />
            </div>
          }
        </div>
        <div className="packageTypesNames">
          <div className="row">
            <div className="col-md-6">
              <div className="packageNames">
                <div className="sponserType">
                  <h5>{t("sponsor")}</h5>
                  {profile ? <p>{profile?.sponsor}</p> : <Skeleton width={175} />}
                </div>
                <div className="placementType">
                  <h5>{t("placement")}</h5>
                  {profile?.father ? <p>{profile?.father}</p> : <Skeleton />}
                </div>
                {plan === "Binary" && (
                  <div className="positionType">
                    <h5>{t("position")}</h5>
                    {profile?.position ? (
                      <p>{profile?.position}</p>
                    ) : (
                      <Skeleton />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                {!!moduleStatus?.product_status && (
                  <div className="col-md-6">
                    <div className="PackageDetailProfile">
                      <h5>{t("package")}</h5>
                      {profile?.package?.name ? (
                        <h6>{profile?.package?.name}</h6>
                      ) : (
                        <Skeleton />
                      )}
                      {!!moduleStatus?.package_upgrade && (
                        <NavLink type="button" className="btn" 
                        onClick={() => {
                          if (!profile?.subscriptionDetails?.showRenew && profile?.subscriptionDetails?.autoRenewalStatus === 1) {
                            toast.error(t("warning cancel auto-renewal first."));
                          }  
                        }}
                          to={{
                            pathname: profile?.subscriptionDetails?.autoRenewalStatus === 0 ? "/upgrade" : "/profile",
                            search: `?previousPage=${encodeURIComponent("/profile")}`
                          }}>
                          {t("upgrade")}
                        </NavLink>
                      )}
                    </div>
                  </div>
                )}
                {!!moduleStatus?.subscription_status && (
                  <div className="col-md-6">
                    <div className="expiryDetailProfile">
                      <h5>{t("expiry")}</h5>
                      {profile?.subscriptionDetails?.productValidity?.productValidityDate ? (
                        <h6>
                          {formatDate(
                            profile?.subscriptionDetails?.productValidity?.productValidityDate
                          )}
                        </h6>
                      ) : (
                        <Skeleton />
                      )}
                      {!profile?.subscriptionDetails?.autoRenewalStatus && profile?.subscriptionDetails.showRenew &&
                        <NavLink
                          type="button"
                          className="btn"
                          to={{
                            pathname: "/renew",
                            search: `?previousPage=${encodeURIComponent("/profile")}`
                          }}
                        >
                          {t("renew")}
                        </NavLink>
                      }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="profileStatusSec">
          <div className="profileStatusBg">
            {/* <div className="profileStatusContDetailSec">
              <div className="profileStatusIconBg">
                <i className="fa-solid fa-user" style={{ color: "#5e28fb" }}></i>
              </div>
              <div className="statusnameCount">
                <h6>{t("personalPV")}</h6>
                {profile?.pv !== null && profile?.pv !== undefined ? (
                  <p>{profile?.pv}</p>
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
            <div className="profileStatusContDetailSec">
              <div className="profileStatusIconBgtwo">
                <i
                  className="fa-solid fa-user-group"
                  style={{ color: "#7e6711" }}
                ></i>
              </div>
              <div className="statusnameCount">
                <h6>{t("groupPV")}</h6>
                {profile?.gpv !== null && profile?.gpv !== undefined ? (
                  <p>{profile?.gpv}</p>
                ) : (
                  <Skeleton />
                )}
              </div>
            </div> */}
            {plan === "Binary" && (
              <>
                <div className="profileStatusContDetailSec">
                  <div className="profileStatusIconBgthree">
                    <i
                      className="fa-solid fa-arrow-left"
                      style={{ color: "#2c628a" }}
                    ></i>
                  </div>
                  <div className="statusnameCount">
                    <h6>{t("leftCarry")}</h6>
                    {profile?.leftCarry !== null &&
                      profile?.leftCarry !== undefined ? (
                      <p>{profile?.leftCarry}</p>
                    ) : (
                      <Skeleton />
                    )}
                  </div>
                </div>
                <div className="profileStatusContDetailSec">
                  <div className="profileStatusIconBgfour">
                    <i
                      className="fa-solid fa-arrow-right"
                      style={{ color: "#207b70" }}
                    ></i>
                  </div>
                  <div className="statusnameCount">
                    <h6>{t("rightCarry")}</h6>
                    {profile?.rightCarry !== null &&
                      profile?.rightCarry !== undefined ? (
                      <p>{profile?.rightCarry}</p>
                    ) : (
                      <Skeleton />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {cancelLoader && <div className="reg-submit-loader-overlay">
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ margin: 'auto', display: 'block', shapeRendering: 'auto' }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <path fill="none" stroke="#7b33c2" stroke-width="8" stroke-dasharray="42.76482137044271 42.76482137044271" d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z" stroke-linecap="round" style={{ transform: 'scale(0.8)', transformOrigin: '50px 50px' }}>
            <animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0;256.58892822265625"></animate>
          </path></svg>
      </div>}
      <Modal className="warning" show={showWarning} onHide={() => setShowWarning(false)}>
        <Modal.Header>
          <Modal.Title>{t("auto_renewal")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("are_you_sure_to_cancel_subscription")}?</p>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton className="btn btn-primary" click={handleCancellation} text="yes"/>
            <SubmitButton className="btn btn-secondary" click={() => setShowWarning(false)} text="no"/>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserProfileExtra;
