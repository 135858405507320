// const BASE_URL = "http://192.168.21.2:5000/node_api/";
const DEFAULT_KEY = 'd410a910-86b6-46bc-b4f7-24e8c493bbe5'

//demo
// const BASE_URL = "https://sajjad.admin.iossmlm.com/node_api/";

// live
const BASE_URL = "https://admin.thegsmart.com/node_api/";


const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
